<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="mt-n8">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-row dense class="mb-5">
              <!-- <v-btn
                color="secondary"
                class="font-weight-bold text-h6 black--text"
                @click="dialogChange = true"
              >
                Change : {{ this.orderTypeText }}
              </v-btn> -->
            </v-row>
          </v-col>

          <v-col cols="14" sm="8" md="8">
            <v-btn
              color="secondary"
              dark
              @click="viewCart"
              style="
                float: right;
                margin-top: -5px;
                margin-bottom: 15px;
                border-radius: 0px 0px 0px 10px;
              "
            >
              <v-icon color="black"> mdi-cart </v-icon>

              <div
                style="
                  border: 3px solid #f8a229;
                  border-radius: 0px 10px 0px 10px;
                  height: 30px;
                  line-height: 25px;
                  width: 25px;
                  background-color: black;
                  font-size: 12px;
                  margin-top: -25px;
                  margin-left: 0px;
                  margin-right: -10px;
                  color: white;
                "
              >
                {{ this.cartDataFinal.length }}
              </div>

              <div class="ml-3 text-h5 black--text font-weight-bold">
                ${{ formatPrice(this.cartNetCostFinal) }}
              </div>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="14" sm="12" md="12" class="">
            <v-row dense>
              <v-col
                v-for="item in products"
                :key="item.description"
                lg="2"
                md="3"
                sm="3"
                xs="4"
                class="pb-3"
                style="margin-top: 1px"
              >
                <!-- <router-link
                    v-bind:to="{
                      path: '/product/' + item.productId
                    }"
                    style="text-decoration: none;"
                  > -->
                <v-hover v-slot="{ hover }">
                  <v-card
                    :height="cardHeight"
                    :width="cardWidth"
                    :style="hover ? ' z-index:1;  transform: scale(1.1);' : ''"
                    id="viewCard"
                    style="cursor: pointer"
                    :elevation="hover ? 2 : 1"
                    :class="{ 'on-hover': hover }"
                    @click="viewProduct(item)"
                    class="mb-2"
                  >
                    <v-img
                      v-if="!item.itemPic"
                      class="white--text align-end"
                      gradient="rgba(248,162,41,.5), rgba(0,0,0,.2)"
                      color="red"
                      width="150"
                      height="100"
                    >
                      <template>
                        <div id="product-image">
                          <v-icon class="text-h1" color="white">
                            mdi-image
                          </v-icon>
                        </div>
                      </template>
                    </v-img>
                    <v-img
                      v-if="item.itemPic"
                      :src="$url + item.itemPic"
                      class="white--text align-end"
                      width="150"
                      height="100"
                    >
                    </v-img>

                    <v-card-text>
                      <div class="headerClass">
                        {{ item.productName }}
                      </div>

                      <v-row style="padding-top: 2px">
                        <v-col md="12">
                          <v-row no-gutters>
                            <v-col md="8">
                              <div
                                v-if="!item.isQuickMenu"
                                class="headerClass"
                                style="font-size: 12px"
                              >
                                ${{ formatPrice(getRetailPrice(item)) }}
                                {{ item.unit ? "/" + item.unit.unitName : "" }}
                              </div>

                              <!-- <div
                            v-if="
                              !item.isQuickMenu &&
                                ((orderType == 2 && item.dinePrice == 0) ||
                                  (orderType == 1 && item.deliveryPrice == 0) ||
                                  (orderType == 0 && item.takeawayPrice == 0))
                            "
                            class="headerClass"
                            style="font-size:12px"
                          >
                            ${{ formatPrice(item.retailPrice) }}
                          </div> -->

                              <div
                                v-if="item.isQuickMenu"
                                class="headerClass"
                                style="
                                  text-decoration: line-through;
                                  color: red;
                                  font-size: 12px;
                                "
                              >
                                ${{ formatPrice(getRetailPrice(item)) }}
                              </div>

                              <div
                                v-if="item.isQuickMenu"
                                class="headerClass"
                                style="font-size: 12px"
                              >
                                ${{ formatPrice(getDiscountPrice(item)) }}
                                <v-icon
                                  style="font-size: 15px; margin-top: -5px"
                                  color="primary"
                                >
                                  mdi-sale
                                </v-icon>
                              </div>

                              <!-- <div
                            v-if="
                              !item.isQuickMenu &&
                                orderType == 2 &&
                                item.dinePrice != 0
                            "
                            class="headerClass"
                            style="font-size:12px"
                          >
                            ${{ formatPrice(item.dinePrice) }}
                            <v-icon
                              style="font-size:15px; margin-top:-5px;"
                              color="primary"
                            >
                              mdi-food-fork-drink
                            </v-icon>
                          </div>

                          <div
                            v-if="
                              !item.isQuickMenu &&
                                orderType == 1 &&
                                item.deliveryPrice != 0
                            "
                            class="headerClass"
                            style="font-size:12px"
                          >
                            ${{ formatPrice(item.deliveryPrice) }}
                            <v-icon
                              style="font-size:15px; margin-top:-5px;"
                              color="primary"
                            >
                              mdi-bike-fast
                            </v-icon>
                          </div>

                          <div
                            v-if="
                              !item.isQuickMenu &&
                                orderType == 0 &&
                                item.takeawayPrice != 0
                            "
                            class="headerClass"
                            style="font-size:12px"
                          >
                            ${{ formatPrice(item.takeawayPrice) }}
                            <v-icon
                              style="font-size:15px; margin-top:-5px;"
                              color="primary"
                            >
                              mdi-food-takeout-box
                            </v-icon>
                          </div>  -->
                            </v-col>

                            <v-col md="4">
                              <div>
                                <qrcode-vue
                                  style="float: right"
                                  v-if="item.barcode"
                                  render-as="svg"
                                  :value="item.barcode"
                                  size="29"
                                  level="H"
                                />
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-hover>
                <!-- </router-link> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- <v-row>
         
          <v-col class="mt-2 mb-2" cols="12" md="12">
            <v-btn
              color="secondary"
              dark
              @click="viewInvoice = false"
              style="float: right;"
            >
              <v-icon color="black">
                mdi-currency-usd
              </v-icon>
              <v-card-text class="black--text font-weight-bold">
                {{ formatPrice(this.cartNetCostFinal) }}
              </v-card-text>
            </v-btn>
          </v-col>
        
        </v-row> -->
      </v-container>
      <v-dialog v-model="dialogChange" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogChange = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to change
            <span class="font-weight-bold ml-2"> {{ orderTypeText }} ?</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogChange = false">
              Cancel
            </v-btn>

            <v-btn @click="orderTypeChange" color="blue darken-1" text>
              OK
            </v-btn>

            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="viewCustomise" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="viewCustomise = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline"> </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <div class="mb-2" style="color: black">
                <v-btn
                  style="width: 100%"
                  outlined
                  color="secondary"
                  dark
                  @click="viewSize"
                >
                  <v-icon color="black"> mdi-plus-circle </v-icon>
                  <v-card-text class="black--text font-weight-bold">
                    Add Size
                  </v-card-text>
                </v-btn>
              </div>
              <v-col v-if="customIndex == 1" cols="14" sm="12" md="12">
                <v-row>
                  <v-col
                    v-for="item in sizesTest"
                    :key="item.description"
                    style="margin-top: 1px"
                    sm="4"
                    xs="4"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        height="100"
                        width="100"
                        id="viewCard"
                        style="cursor: pointer"
                        :style="
                          hover ? ' z-index:1; transform: scale(1.1);' : ''
                        "
                        :elevation="hover ? 2 : 1"
                        :class="{ 'on-hover': hover }"
                      >
                        <v-img
                          :src="
                            item.image ||
                            'https://image.shutterstock.com/image-photo/close-ketchup-tomatoes-placed-on-260nw-1729789327.jpg'
                          "
                          class="white--text align-end"
                          width="100"
                          height="100"
                        >
                          <v-tooltip
                            v-if="itemSize != item.sizeId"
                            top
                            color="black"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                style="
                                  margin-top: -58px;
                                  float: right;
                                  background-color: white;
                                  border-radius: 0px 0px 0px 5px;
                                "
                              >
                                <v-icon
                                  color="black"
                                  @click="addSize(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-plus-circle
                                </v-icon>
                              </div>
                            </template>
                            <span>Add {{ item.sizeName }}</span>
                          </v-tooltip>

                          <!-- <v-tooltip
                            v-if=" itemSize == item.sizeId"
                            top
                            color="black"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                style="margin-top: -58px; float:right;  background-color: white; border-radius: 0px 0px 0px 5px;"
                              >
                                <v-icon
                                  color="red"
                                  @click="deleteSize(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-close-circle
                                </v-icon>
                              </div>
                            </template>
                            <span>Delete {{ item.sizeName }}</span>
                          </v-tooltip> -->

                          <div
                            v-if="itemSize == item.sizeId"
                            style="
                              margin-top: -30px;
                              margin-left: 35%;
                              float: left;
                              background-color: #ffffff95;
                            "
                          >
                            <v-icon style="font-size: 30px" color="black">
                              mdi-checkbox-marked
                            </v-icon>
                          </div>

                          <div class="customIcons">
                            <div class="headerClass">
                              {{ item.sizeName }}
                            </div>

                            <v-row>
                              <v-col>
                                <div
                                  class="headerClass"
                                  style="font-size: 12px"
                                >
                                  ${{ formatPrice(item.unitPrice) }}
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>
              <div v-if="sizeAdded" class="mb-2" style="color: black">
                <v-btn
                  style="width: 100%"
                  outlined
                  color="secondary"
                  dark
                  @click="viewAddOn"
                >
                  <v-icon color="black"> mdi-plus-circle </v-icon>
                  <v-card-text class="black--text font-weight-bold">
                    Add AddOn
                  </v-card-text>
                </v-btn>
              </div>
              <v-col v-if="customIndex == 2" cols="14" sm="12" md="12">
                <v-row>
                  <v-col
                    v-for="item in addonItemsTest"
                    :key="item.description"
                    style="margin-top: 1px"
                    sm="4"
                    xs="4"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        height="100"
                        width="100"
                        id="viewCard"
                        style="cursor: pointer"
                        :style="
                          hover
                            ? ' z-index:1; position: fixed; transform: scale(1.1);'
                            : ''
                        "
                        :elevation="hover ? 12 : 1"
                        :class="{ 'on-hover': hover }"
                      >
                        <v-img
                          :src="
                            item.image ||
                            'https://image.shutterstock.com/image-photo/close-ketchup-tomatoes-placed-on-260nw-1729789327.jpg'
                          "
                          class="white--text align-end"
                          width="100"
                          height="100"
                        >
                          <v-tooltip v-if="!item.added" top color="black">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                style="
                                  margin-top: -58px;
                                  float: right;
                                  background-color: white;
                                  border-radius: 0px 0px 0px 5px;
                                "
                              >
                                <v-icon
                                  color="black"
                                  @click="addAddOnItem(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-plus-circle
                                </v-icon>
                              </div>
                            </template>
                            <span>Add {{ item.addonItemName }}</span>
                          </v-tooltip>

                          <v-tooltip v-if="item.added" top color="black">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                style="
                                  margin-top: -58px;
                                  float: right;
                                  background-color: white;
                                  border-radius: 0px 0px 0px 5px;
                                "
                              >
                                <v-icon
                                  color="red"
                                  @click="deleteAddOnItem(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-close-circle
                                </v-icon>
                              </div>
                            </template>
                            <span>Delete {{ item.addonItemName }}</span>
                          </v-tooltip>

                          <div
                            v-if="item.added"
                            style="
                              margin-top: -30px;
                              margin-left: 35%;
                              float: left;
                              background-color: #ffffff95;
                            "
                          >
                            <v-icon style="font-size: 30px" color="black">
                              mdi-checkbox-marked
                            </v-icon>
                          </div>

                          <div class="customIcons">
                            <div class="headerClass">
                              {{ item.addonItemName }}
                            </div>

                            <v-row>
                              <v-col>
                                <div
                                  class="headerClass"
                                  style="font-size: 12px"
                                >
                                  ${{ formatPrice(item.unitPrice) }}
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>

              <div v-if="addOnAdded" class="mb-2" style="color: black">
                <v-btn
                  style="width: 100%"
                  outlined
                  color="secondary"
                  dark
                  @click="viewIngredient"
                >
                  <v-icon color="black"> mdi-minus-circle </v-icon>
                  <v-card-text class="black--text font-weight-bold">
                    Remove Ingredient
                  </v-card-text>
                </v-btn>
              </div>
              <v-col v-if="customIndex == 3" cols="14" sm="12" md="12">
                <v-row>
                  <v-col
                    v-for="item in ingredientsTest"
                    :key="item.description"
                    style="margin-top: 1px"
                    sm="4"
                    xs="4"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        height="100"
                        width="100"
                        id="viewCard"
                        style="cursor: pointer"
                        :style="
                          hover
                            ? ' z-index:1; position: fixed; transform: scale(1.1);'
                            : ''
                        "
                        :elevation="hover ? 12 : 1"
                        :class="{ 'on-hover': hover }"
                      >
                        <v-img
                          :src="
                            item.image ||
                            'https://image.shutterstock.com/image-photo/close-ketchup-tomatoes-placed-on-260nw-1729789327.jpg'
                          "
                          class="white--text align-end"
                          width="100"
                          height="100"
                        >
                          <v-tooltip v-if="!item.added" top color="black">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                style="
                                  margin-top: -58px;
                                  float: right;
                                  background-color: white;
                                  border-radius: 0px 0px 0px 5px;
                                "
                              >
                                <v-icon
                                  color="black"
                                  @click="addIngredient(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-plus-circle
                                </v-icon>
                              </div>
                            </template>
                            <span>Add {{ item.ingredientName }}</span>
                          </v-tooltip>

                          <v-tooltip v-if="item.added" top color="black">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                style="
                                  margin-top: -58px;
                                  float: right;
                                  background-color: white;
                                  border-radius: 0px 0px 0px 5px;
                                "
                              >
                                <v-icon
                                  color="red"
                                  @click="deleteIngredient(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-close-circle
                                </v-icon>
                              </div>
                            </template>
                            <span>Delete {{ item.ingredientName }}</span>
                          </v-tooltip>

                          <div
                            v-if="item.added"
                            style="
                              margin-top: -30px;
                              margin-left: 35%;
                              float: left;
                              background-color: #ffffff95;
                            "
                          >
                            <v-icon style="font-size: 30px" color="black">
                              mdi-checkbox-marked
                            </v-icon>
                          </div>

                          <div class="customIcons">
                            <div class="headerClass">
                              {{ item.ingredientName }}
                            </div>

                            <v-row>
                              <v-col>
                                <div
                                  class="headerClass"
                                  style="font-size: 12px"
                                >
                                  ${{ formatPrice(item.unitPrice) }}
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-form ref="editedForm">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      label="AddOn"
                      :items="addons"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-select label="Size" :items="sizes" outlined clearable />
                  </v-col>
                </v-row>
              </v-form> -->
            </v-container>
          </v-card-text>

          <!-- <v-card-actions>
            <v-spacer />
            
            <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogViewInfo" max-width="250" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogViewInfo = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title
            style="
              font-size: 20px;
              font-weight: bold;
              text-transform: uppercase;
            "
          >
            {{ viewInfo.productName }}
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-title
            style="
              font-size: 15px;
              font-weight: bold;
              text-transform: uppercase;
            "
          >
            Ingredients
          </v-card-title>

          <v-card-text>
            <div>Ingredient 1</div>
            <div>Ingredient 2</div>
            <div>Ingredient 3</div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title
            style="
              font-size: 15px;
              font-weight: bold;
              text-transform: uppercase;
            "
          >
            Add Ons
          </v-card-title>

          <v-card-text>
            <div>Add On 1</div>
            <div>Add On 2</div>
            <div>Add On 3</div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogViewProduct"
        max-width="400px"
        transition="slide-x-reverse-transition"
      >
        <v-card style="background-color: red">
          <ViewProductComponent
            :closeView="closeViewProduct"
            :singleItem="singleItem"
            :cartCount="cartDataFinal.length"
            @updateCart="updateCart($event)"
          />

          <!-- </v-container> -->
        </v-card>
        <!-- <v-card>
          <v-container>
            <v-img
              :src="singleItem.image"
              width="200px"
              height="200px"
              style=" left: 50%;  border-radius: 5%; margin-top:10px; transform: translateX(-50%)"
            >
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    style="float:right; background-color:white; border-radius: 10px 0px 10px 0px;"
                  >
                    <v-icon
                      color="black"
                      @click="moreInfoItem(singleItem)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </div>
                </template>
                <span>More Info</span>
              </v-tooltip>
            </v-img>
          </v-container>

          <v-card-title class="justify-center">
            <span style="font-size: 25px; font-weight : bold">
              {{ singleItem.productName }}
            </span>
          </v-card-title>

          <v-card-title class="justify-center" style="margin-top:-10px">
            <div>
              <span style="font-size: 15px;">
                <v-icon style="font-size:20px">
                  mdi-currency-usd
                </v-icon>
                {{ formatPrice(singleItem.retailPrice) }}
              </span>
            </div>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-col cols="12">
                <v-row style="margin-bottom:10%; ">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      tile
                      style="width:100%"
                      @click="customise"
                      color="secondary"
                      dark
                      :style="{ left: '50%', transform: 'translateX(-50%)' }"
                    >
                      <v-card-text
                        :style="hover ? 'font-size:17px' : 'font-size:15px'"
                        :class="{ 'on-hover': hover }"
                        class="black--text font-weight-bold"
                      >
                        Customise
                      </v-card-text>
                    </v-btn>
                  </v-hover>
                </v-row>

                <v-row
                  style="width:50%; margin-left:50%; transform: translateX(-50%);"
                >
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :style="
                        hover
                          ? ' transform: scale(1.3);'
                          : ' min-width: 30px; width: 30px'
                      "
                      :class="{ 'on-hover': hover }"
                      color="black"
                      tile
                      dark
                      small
                      style=" min-width: 30px; width: 30px"
                      @click="decreaseCount"
                    >
                      <v-icon style="font-size:20px">
                        mdi-minus
                      </v-icon>
                    </v-btn>
                  </v-hover>
                  <v-text-field
                    v-model="count"
                    class="inputNumber"
                    placeholder="1"
                    color="secondary"
                    hide-details
                    dense
                    single-line
                    type="number"
                    style=" width: 5%; margin-top:-2%;"
                  ></v-text-field>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :style="
                        hover
                          ? ' transform: scale(1.3);'
                          : ' min-width: 30px; width: 30px'
                      "
                      :class="{ 'on-hover': hover }"
                      color="secondary"
                      tile
                      dark
                      small
                      style=" min-width: 30px; width: 30px"
                      @click="increaseCount"
                    >
                      <v-icon style="font-size:20px" class="black--text">
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-hover>
                </v-row>
              </v-col>
              <v-row
                style=" float:right"
                v-if="this.productCost && this.count > 1"
              >
                <span style="font-size: 15px; color: black;">
                  Net :
                  <v-icon style="font-size:20px; ">
                    mdi-currency-usd
                  </v-icon>
                  {{ formatPrice(this.productCost) }}
                </span>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-hover v-slot="{ hover }">
                <v-btn
                  color="black"
                  tile
                  dark
                  style="width:50%"
                  @click="closeViewProduct"
                >
                  <v-card-text
                    :style="hover ? 'font-size:17px' : 'font-size:15px'"
                    :class="{ 'on-hover': hover }"
                    class="white--text font-weight-bold"
                  >
                    Cancel
                  </v-card-text>
                </v-btn>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  color="secondary"
                  tile
                  dark
                  style="width:50%"
                  @click="addToCart"
                >
                  <v-card-text
                    :style="hover ? 'font-size:17px' : 'font-size:15px'"
                    :class="{ 'on-hover': hover }"
                    class="black--text font-weight-bold"
                  >
                    Add to Cart
                  </v-card-text>
                </v-btn>
              </v-hover>
            </v-row>
          </v-card-text>
        </v-card> -->
      </v-dialog>

      <v-dialog
        v-model="addDelivery"
        max-width="500px"
        transition="slide-x-reverse-transition"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-card-text>
                <v-tabs grow>
                  <v-tab class="tabHeader"> Pick Up</v-tab>
                  <v-tab-item style="background-color: rgb(238, 238, 238)">
                    <div style="color: black; text-align: center">Option 1</div>
                  </v-tab-item>
                  <v-tab class="tabHeader"> Delivery</v-tab>
                  <v-tab-item style="background-color: rgb(238, 238, 238)">
                    <div style="color: black; text-align: center">Option 2</div>
                  </v-tab-item>
                  <v-tab class="tabHeader">Dine In </v-tab>
                  <v-tab-item style="background-color: rgb(238, 238, 238)">
                    <div style="color: black; text-align: center">Option 3</div>
                  </v-tab-item>
                </v-tabs>

                <!-- <div style="color : black; text-align: center">
                  Options
                </div> -->
              </v-card-text>

              <v-card-text>
                <!-- <div style="color : black; text-align: center">
                  Customer Details
                </div> -->

                <v-container style="background-color: whitesmoke">
                  <v-form ref="CustomerForm">
                    <v-row>
                      <v-col cols="12" style="margin-bottom: -10%">
                        <v-text-field
                          v-model="customer.custName"
                          label="Name"
                          clearable
                        />
                      </v-col>
                      <v-col cols="12" style="margin-bottom: -10%">
                        <v-text-field
                          v-model="customer.custPhone"
                          label="Phone No"
                          clearable
                        />
                      </v-col>
                      <v-col cols="12" style="margin-bottom: -10%">
                        <v-text-field
                          v-model="customer.custAddress"
                          label="Address"
                          clearable
                        />
                      </v-col>

                      <v-col cols="12" style="">
                        <v-text-field
                          v-model="customer.custPostalCode"
                          label="Postal Code"
                          clearable
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-container>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-btn
                color="black"
                tile
                dark
                style="width: 50%"
                @click="addDelivery = false"
              >
                <v-card-text> Cancel </v-card-text>
              </v-btn>

              <v-btn color="primary" tile dark style="width: 50%">
                <v-card-text> Place Order </v-card-text>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="viewOrder" max-width="800px" clearable fullscreen>
        <v-card>
          <v-btn
            color="black"
            icon
            dark
            @click="viewOrder = false"
            style="float: right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-card-text>
            <v-container>
              <v-card-text>
                <v-col cols="12">
                  <!-- <v-card-text>Invoice Number - {{ this.billNo }}</v-card-text>
                  <vue-barcode value="1234567890"></vue-barcode> -->
                  <v-data-table
                    :headers="headers"
                    :items="cartData"
                    :search="search"
                    hide-default-header
                    hide-default-footer
                    style="color: black"
                    id="virtual-scroll-table"
                  >
                    <!-- v-scroll:#virtual-scroll-table="onScroll" -->
                    <template #header="{ props: { headers } }">
                      <thead>
                        <tr>
                          <th
                            v-for="header in headers"
                            :key="header.value"
                            style="text-transform: uppercase; font-weight: bold; color:primary font-size:20px; "
                            scope="col"
                          >
                            {{ header.text }}
                          </th>
                        </tr>
                      </thead>
                    </template>

                    <template v-slot:[`item.image`]="{ item }">
                      <v-list-item-avatar size="50" v-if="item.image">
                        <v-img :src="item.image" />
                      </v-list-item-avatar>
                      <div v-else class="ml-2">No Image</div>
                    </template>
                    <template v-slot:[`item.unitPrice`]="{ item }">
                      {{ formatPrice(item.unitPrice) }}
                    </template>

                    <template v-slot:[`item.cost`]="{ item }">
                      {{ formatPrice(item.cost) }}
                    </template>

                    <template v-slot:[`item.delete`]="{ item }">
                      <v-hover v-slot="{ hover }">
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="closeButton"
                              @click="deleteItem(item)"
                              :elevation="hover ? 12 : 1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-delete-circle
                            </v-icon>
                          </template>
                          <span>Delete </span>
                        </v-tooltip>
                      </v-hover>
                      <!-- <v-icon color="#FF0000" @click="deleteItem(item)">
                        mdi-delete-circle
                      </v-icon> -->
                    </template>
                  </v-data-table>
                </v-col>
              </v-card-text>
              <v-col cols="12">
                <v-card-text>
                  <v-row style="font-size: 18px; color: black">
                    <v-col cols="6">
                      <div>Bill Total</div>
                    </v-col>
                    <v-col cols="2">
                      <div>:</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="float-right">
                        {{ formatPrice(this.cartNetCost) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text>
                  <v-row style="font-size: 18px; color: black">
                    <v-col cols="6">
                      <div>Discount</div>
                    </v-col>
                    <v-col cols="2">
                      <div>:</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="float-right">
                        {{ formatPrice(this.discount) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-row style="font-size: 18px; color: black">
                    <v-col cols="6">
                      <div>GST</div>
                    </v-col>
                    <v-col cols="2">
                      <div>:</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="float-right">
                        {{ formatPrice(this.gst) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text>
                  <v-row
                    style="
                      font-size: 18px;
                      font-weight: bold;
                      color: primary;
                      text-transform: uppercase;
                    "
                  >
                    <v-col cols="6">
                      <div>Net Total</div>
                    </v-col>
                    <v-col cols="2">
                      <div>:</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="float-right">
                        {{ formatPrice(this.cartNetCost) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
              <v-row>
                <!-- <v-btn
                  color="black"
                  dark
                  tile
                  
                  @click="viewOrder = false"
                >
                  <v-card-text> Close </v-card-text>
                </v-btn> -->

                <v-btn
                  color="black"
                  dark
                  tile
                  :style="{ left: '50%', transform: 'translateX(-50%)' }"
                  @click="addDelivery = true"
                >
                  <v-card-text> Checkout </v-card-text>
                </v-btn>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="450px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to remove {{ editedItem.itemName }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
@media screen and (max-width: 300px) {
  #viewCard {
    height: "100px";
    width: "100px";
  }
}

#product-image {
  margin: auto;
  transform: translate(0, -50%);
  text-align: center;
}

.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: bold;
}

.customIcons {
  padding-left: 10%;
  background-color: #ffffff95;
}

.row1 {
  margin-top: -25px;
}

.mainLayout {
  height: 440px;
  overflow: hidden;
  overflow-y: auto;
}

.addbutton {
  float: right;
}

.temp {
  background-color: rgb(238, 238, 238);
}

.inputNumber input[type="number"] {
  -moz-appearance: textfield;
}

.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.inputNumber input::placeholder {
  color: black !important;
  opacity: 1;
}

.inputNumber input {
  color: black !important;
  text-align: center;
  font-weight: bold;
}

#virtual-scroll-table {
  max-height: 300px;
  overflow: auto;
}
</style>
<script>
import MaterialCard from "../components/MaterialCard.vue";
import { mapState, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import QrcodeVue from "qrcode.vue";
import Vue from "vue";
Vue.component("qrcode-vue", QrcodeVue);

import ViewProductComponent from "./components/Product Order.vue";

export default {
  components: { ViewProductComponent, MaterialCard, Loading },
  data() {
    return {
      dialogChange: false,
      viewCustomise: false,
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,

      dialogViewInfo: false,
      viewInfo: {},
      currentUrl: "",

      dialogViewProduct: false,
      addDelivery: false,
      viewInvoice: false,
      viewOrder: false,
      dialogTrash: false,

      netCost: 0,
      discount: 0,
      gst: 0,
      netBill: 0,

      singleItem: [],

      singleOrder: {
        itemName: "",
        unitPrice: "",
        quantity: "",
        cost: "",
      },

      count: 1,
      dialogDelete: false,
      dialogPermanentDelete: false,
      dialogRestore: false,
      dateModal: false,
      search: "",

      headers: [
        { text: "", value: "image" },
        { text: "Item", value: "itemName" },
        { text: "Qty", value: "quantity" },
        { text: "Unit", value: "unitPrice" },
        { text: "Price", value: "cost" },
        { text: "", value: "delete" },
      ],
      categories: [],

      editedIndex: -1,
      customIndex: 0,
      editedItem: {
        itemName: "",
        unitPrice: "",
        quantity: "",
        cost: "",
      },
      defaultItem: {
        itemName: "",
        unitPrice: "",
        quantity: "",
        cost: "",
      },
      productCost: null,
      trashed: [],
      page: 1,
      pageCount: 10,
      start: 0,
      end: 11,
      products: [],
      productPrices: [],
      customer: {
        custName: "",
        custAddress: "",
        custPostalCode: "",
        custPhone: "",
      },
      bills: {},
      billNo: "",
      addons: [],
      addonItems: [],
      sizes: [],
      ingredients: [],
      orderType: "",
      orderTypeText: "Select Order Type",
      itemSize: 0,
      sizeAdded: false,

      itemAddon: [],
      itemAddonName: [],
      itemAddonPrices: [],
      addOnAdded: false,

      itemIngredient: [],
      itemIngredientName: [],

      sizesTest: [],
      addonItemsTest: [],
      ingredientsTest: [],
      itemId: "",

      defaultSize: "",
      cartDataFinal: [],
      cartNetCostFinal: 0,
      units: [],
    };
  },

  computed: {
    ...mapState(["menuSubList"]),

    ...mapState(["cartData"]),
    ...mapState(["cartNetCost"]),
    cardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 200;
        case "sm":
          return 200;
        case "md":
          return 200;
        case "lg":
          return 200;
        case "xl":
          return 200;
      }
    },
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 140;
        case "sm":
          return 150;
        case "md":
          return 150;
        case "lg":
          return 150;
        case "xl":
          return 150;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.changePage();
    },
  },

  created() {
    var currentUrl = window.location.pathname;
    let vars = currentUrl.split("/");
    vars.reverse();
    let itemId = vars[0];
    this.itemId = parseInt(itemId);
    this.getLocalStore();

    this.getDataFromApi();

    var orderTypeLocal = window.localStorage.getItem("orderTypeStore");
    this.orderType = orderTypeLocal;
    console.log("this.orderType", this.orderType);

    if (this.orderType == 0) {
      this.orderTypeText = "Pick Up";
    }
    if (this.orderType == 1) {
      this.orderTypeText = "Delivery";
    }
    if (this.orderType == 2) {
      this.orderTypeText = "Dine In";
    }
  },

  methods: {
    getLocalStore() {
      if (localStorage.getItem("cartDataStore") !== null) {
        var cartDataLocal = JSON.parse(
          window.localStorage.getItem("cartDataStore")
        );
        this.cartDataFinal = cartDataLocal;
        var cartNetCostLocal = window.localStorage.getItem("cartNetCostStore");
        this.cartNetCostFinal = cartNetCostLocal;
      } else {
        this.cartDataFinal = this.cartData;
        this.cartNetCostFinal = this.cartNetCost;
      }
    },
    getRetailPrice(item) {
      return item.retailPrice;
      // if (item.unitId == "1") {
      //   return item.retailPrice * 1000;
      // } else {
      //   return item.retailPrice;
      // }
    },
    getDiscountPrice(item) {
      return item.discountPrice;
      // if (item.unitId == "1") {
      //   return item.discountPrice * 1000;
      // } else {
      //   return item.discountPrice;
      // }
    },

    ...mapMutations(["ADD_CART_DATA"]),
    ...mapMutations(["UPDATE_NET_COST"]),
    ...mapMutations(["ADD_NET_COST"]),
    ...mapMutations(["ADD_MENU_NAME"]),

    closeViewProduct() {
      this.dialogViewProduct = false;
    },

    updateCart() {
      var cartNetCostLocal = window.localStorage.getItem("cartNetCostStore");
      //  console.log("window.localStorage.getItem.cartNetCostStore",cartNetCostLocal)
      this.cartNetCostFinal = cartNetCostLocal;

      var cartDataLocal = JSON.parse(
        window.localStorage.getItem("cartDataStore")
      );
      this.cartDataFinal = cartDataLocal;
      this.singleItem = {};
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDataFromApi() {
      this.isLoading = true;

      this.$http.get("/Unites/").then((response) => {
        this.units = response.data.data;
      });

      this.$http
        .get("/Menus")
        .then((response) => {
          this.subDepartments = response.data.filter(
            (a) => a.subDepartmentId === this.itemId
          );
          this.ADD_MENU_NAME(this.subDepartments[0].subDepartmentName);
          // console.log("Menu : ", this.subDepartments[0].subDepartmentName);
        })
        .catch((error) => {
          this.ADD_MENU_NAME("Home");
        });

      this.$http
        .get(`/Menus/Subdepartments/Products/${this.itemId}`)
        .then((response) => {
          // console.log(response);
          this.products = response.data;

          if (this.products.length != 0) {
            this.isLoading = false;
          } else {
            if (error) {
              this.$toast.error("No Data Found", "Error", {
                position: "topRight",
              });
              this.isLoading = false;
            }
            this.isLoading = false;
          }
        })
        .catch((error) => {
          // this.isLoading = false;
          if (error) {
            //   console.log(error);
            // this.$toast.error("No Data Found", "Error", {
            //   position: "topRight"
            // });
            this.isLoading = false;
          }
        });
    },

    moreInfoItem(item) {
      //      this.editedIndex = this.dataFiltered.indexOf(item);
      this.viewInfo = Object.assign({}, item);
      this.dialogViewInfo = true;
    },
    getUnit(id) {
      var unit = this.units.find((e) => e.unitID == id);
      if (unit) {
        return unit.unitName;
      } else {
        return "";
      }
    },
    viewProduct(item) {
      this.isLoading = true;

      // this.sizeAdded = false;
      // this.addOnAdded = false;
      // this.itemSize = 0;
      // this.itemAddon = [];
      // this.itemAddonName = [];
      // this.itemIngredient = [];
      // this.itemIngredientName = [];

      // this.count = 1;

      //this.singleItem = item;
      //console.log("item info : ", this.singleItem);

      //this.productCost = null;

      this.$http.get(`/Menus/Products/${item.productId}`).then((response) => {
        this.singleItem = response.data;
        console.log("this.singleItem", this.singleItem);

        // var unitName = this.getUnit(this.singleItem.unitId);
        // if (unitName == "Kg") {
        //   this.singleItem.retailPrice = this.singleItem.retailPrice * 1000;
        //   this.singleItem.discountPrice = this.singleItem.discountPrice * 1000;
        // }

        this.dialogViewProduct = true;

        this.isLoading = false;
      });
    },
    addSize(item) {
      item.added = true;
      this.sizeAdded = true;

      this.itemSize = item.sizeId;

      // console.log(item);
      // console.log(this.itemSize);
      this.customIndex = 0;
    },
    deleteSize(item) {
      item.added = false;
      this.itemSize = 0;
      //   console.log(this.itemSize);
    },

    addAddOnItem(item) {
      item.added = true;
      this.addOnAdded = true;
      //this.customIndex = 0;
      this.itemAddon.unshift(item.addonItemId);
      this.itemAddon = this.removeDuplicate(this.itemAddon);

      this.itemAddonName.unshift(item.addonItemName);
      this.itemAddonName = this.removeDuplicate(this.itemAddonName);

      // console.log("itemAddonName", this.itemAddonName);
      this.customIndex = 0;
    },

    deleteAddOnItem(item) {
      item.added = false;
      //  this.customIndex = 0;
      //  console.log(item.addonItemId);
      for (var i = 0; i < this.itemAddon.length; i++) {
        if (this.itemAddon[i] === item.addonItemId) {
          this.itemAddon.splice(i, 1);
          this.itemAddonName.splice(i, 1);
        }
      }
      //  console.log(this.itemAddon);
    },
    addIngredient(item) {
      item.added = true;
      //this.addOnAdded = true;
      //  console.log(item);
      //this.customIndex = 0;
      this.itemIngredient.unshift(item.ingredientId);
      this.itemIngredient = this.removeDuplicate(this.itemIngredient);

      this.itemIngredientName.unshift(item.ingredientName);
      this.itemIngredientName = this.removeDuplicate(this.itemIngredientName);

      //  console.log("itemIngredientName", this.itemIngredientName);
      this.customIndex = 0;
    },

    customise() {
      this.viewCustomise = true;
      this.customIndex = 0;

      this.$http.get("/Ingredient/").then((response) => {
        this.ingredients = response.data.data;
      });
      this.$http.get("/Size/").then((response) => {
        this.sizes = response.data.data;
      });
      //      console.log(this.products);
    },
    increaseCount() {
      this.count = parseInt(this.count) + 1;
      this.productCost = this.singleItem.retailPrice * this.count;
    },
    decreaseCount() {
      if (this.count > 1) {
        this.count = parseInt(this.count) - 1;
        this.productCost = this.singleItem.retailPrice * this.count;
      }
    },

    viewCart() {
      //      this.$router.push("/cart");

      if (this.cartDataFinal.length > 0) {
        this.$router.push("/cart");
      } else {
        alert("Your Cart is Empty!");
      }
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.cartData.indexOf(item);

      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$toast.success("Item removed successfully.", "Success", {
        position: "topRight",
      });
      // console.log(this.editedItem);
      //  console.log(this.editedItem.itemName);
      this.cartData.splice(this.editedIndex, 1);

      this.UPDATE_NET_COST(this.editedItem.cost);

      //this.cartNetCost = this.cartNetCost - this.editedItem.cost;
      //   console.log(this.editedItem.cost);
      //   console.log(this.cartNetCost);

      this.closeDelete();
      if (this.cartData.length == 0) {
        this.viewOrder = false;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogPermanentDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      if (this.editedItem.categoryName && this.editedItem.description) {
        this.save();
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight",
        });
      }
    },

    save() {
      this.$http
        .post("/ProductCategory", this.editedItem)
        .then((response) => {
          if (response.data.success === true) {
            this.categories.unshift(response.data.data);
            this.updateData();
            this.$toast.success(
              "Product Category added successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.close();
    },

    viewSize() {
      if (this.customIndex != 1) {
        this.customIndex = 1;
        this.$http.get("/Size/").then((response) => {
          this.sizesTest = response.data.data;
          // console.log(this.sizesTest);
        });
      } else {
        this.customIndex = 0;
      }
    },
    viewAddOn() {
      if (this.customIndex != 2) {
        this.customIndex = 2;
        this.$http.get("/AddonItem/").then((response) => {
          this.addonItemsTest = response.data.data;
          // console.log(this.addonItemsTest);
        });
      } else {
        this.customIndex = 0;
      }
    },
    viewIngredient() {
      if (this.customIndex != 3) {
        this.customIndex = 3;
        this.$http.get("/Ingredient/").then((response) => {
          this.ingredientsTest = response.data.data;
          //  console.log(this.ingredientsTest);
        });
      } else {
        this.customIndex = 0;
      }
    },
    orderTypeChange() {
      //this.cartData = [];
      //this.cartData.splice(this.editedIndex, 1);
      // this.cartData.splice(0, this.cartData.length);
      // this.menuSubList.splice(0, this.menuSubList.length);

      //this.cartNetCost = null;
      // this.ADD_NET_COST(-this.cartNetCost);

      this.$router.push("/landing");
      // window.localStorage.clear();
    },

    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    },
    removeDuplicate(array) {
      var uniqueArray = [];

      // Loop through array valuesarra
      if (array.length >= 0) {
        for (var i = 0; i < array.length; i++) {
          if (uniqueArray.indexOf(array[i]) === -1) {
            uniqueArray.push(array[i]);
          }
        }
        return uniqueArray;
      }
    },
  },
};
</script>
